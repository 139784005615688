<template>
  <v-layout class="custom-phone-input">
    <v-flex class="country-code">
      <v-select
        dense
        filled
        label="Country"
        solo
        flat
        v-model="countryCode"
        :items="countryList"
        hide-details
        color="cyan"
        :disabled="disabled"
        :loading="loading"
        item-text="name"
        item-value="iso2"
        item-color="cyan"
        return-object
        v-on:change="updateActiveCountry"
      >
        <template v-slot:selection>
          <div
            :class="activeCountry.iso2.toLowerCase()"
            class="vti__flag mr-2"
          />
        </template>
        <template v-slot:item="data">
          <span :class="data.item.iso2.toLowerCase()" class="vti__flag mr-2" />
          <span>{{ data.item.name }} {{ `+${data.item.dialCode}` }}</span>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="width-100">
      <v-text-field
        :key="phoneKey"
        :id="id"
        v-mask="phoneMask"
        v-model="phone"
        :rules="rules"
        dense
        filled
        :disabled="disabled"
        :loading="loading"
        :label="label"
        solo
        flat
        color="cyan"
        :hide-details="hideDetails"
        v-on:keyup="
          $emit('input', phone);
          $emit('change', phone);
          validateNumber(phone);
        "
        v-on:change="
          $emit('input', phone);
          $emit('change', phone);
          validateNumberMala(phone);
        "
        v-on:blur="
          $emit('input', phone);
          $emit('change', phone);
        "
        v-on:focus="
          $emit('input', phone);
          $emit('change', phone);
        "
      >
      </v-text-field>
      <template v-if="invalidNumber">
        <span style="color: red; font-weight: 600">{{ invalidNumber }}</span>
      </template>
    </v-flex>
  </v-layout>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import allCountries from "@/core/plugins/country-list";
import LocalService from "@/core/services/local.service";
import { QUERY } from "@/core/services/store/request.module";
export default {
  mixins: [ValidationMixin],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "phone",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Phone Number",
    },
    phoneKey: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    value(param1, param2) {
      if (param1 != param2) {
        this.setValues();
      }
    },
  },
  data() {
    return {
      phone: null,
      validateNumberCheck: null,
      invalidNumber: null,
      countryCode: {
        areaCodes: null,
        dialCode: "65",
        iso2: "SG",
        name: "Singapore",
        priority: 0,
        phone_character_limit: 8,
      },
      activeCountry: {
        areaCodes: null,
        dialCode: "65",
        iso2: "SG",
        name: "Singapore",
        priority: 0,
        phone_character_limit: 8,
      },
      phoneMask: null,
      countryList: [],
      allCountries: allCountries,
    };
  },
  methods: {
    validateNumber(number) {
      if (number && number.length) {
        let maxLimit = Number(number.length) - 4;
        if (number && this.countryCode.phone_character_limit == maxLimit) {
          const _this = this;
          let apiKey = process.env.VUE_APP_MOBILE_KEY;
          _this.$store
            .dispatch(QUERY, {
              url: "https://micro.businessthrust.com/api/check-mobile",
              data: {
                mobile: number,
                apikey: apiKey,
              },
            })
            .then(({ data }) => {
              this.validateNumberCheck = data.result;
              if (
                this.validateNumberCheck &&
                !this.validateNumberCheck.valid &&
                this.validateNumberCheck.line_type == "N/A"
              ) {
                (this.phone = null), (this.invalidNumber = "Invalid Number");
              } else {
                this.invalidNumber = null;
              }
            })
            .catch((error) => {
              _this.logError(error);
            });
        }
      }
    },
    validateNumberMala(number) {
      if (number) {
        const _this = this;
        _this.loading = true;
        let apiKey = process.env.VUE_APP_MOBILE_KEY;
        _this.$store
          .dispatch(QUERY, {
            url: "https://micro.businessthrust.com/api/check-mobile",
            data: {
              mobile: number,
              apikey: apiKey,
            },
          })
          .then(({ data }) => {
            this.validateNumberCheck = data.result;
            if (
              this.validateNumberCheck &&
              !this.validateNumberCheck.valid &&
              this.validateNumberCheck.line_type == "N/A"
            ) {
              (this.phone = null), (this.invalidNumber = "Invalid Number");
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.loading = false;
          });
      }
    },

    getActiveCountries() {
      this.countryList = LocalService.getData("phone_countries");
      this.countryCode = this.lodash.find(this.countryList, {
        dialCode: "65",
      });
      if (!this.countryCode) {
        this.countryCode = this.countryList[0];
      }
    },
    setValues() {
      const value = this.lodash.replace(this.value, "+", "");
      const phoneCode = this.lodash.split(value, "-", 1);
      if (phoneCode && phoneCode[0]) {
        const countryCode = this.lodash.find(this.countryList, {
          dialCode: phoneCode[0],
        });

        if (countryCode) {
          this.countryCode = countryCode;
          this.activeCountry = countryCode;
        }
      }
      this.phone = this.value ? this.value : null;
      this.$nextTick(() => {
        this.phone = this.value ? this.value : null;
      });
    },
    updateActiveCountry() {
      const { dialCode, phone_character_limit } = this.countryCode;
      if (dialCode) {
        var str = new Array(phone_character_limit + 1).join("#");
        this.phoneMask = `+${dialCode}-${str}`;
        /* if (dialCode == "65") {
          this.phoneMask = `+${dialCode}-########`;
        } else {
          this.phoneMask = `+${dialCode}-############`;
        } */
      }
      this.phone = null;
      this.activeCountry = this.countryCode;
      this.$nextTick(() => {
        this.phone = null;
      });
      this.invalidNumber = null;
    },
  },
  computed: {
    rules() {
      const { dialCode } = this.countryCode;
      /*   let maxLimitss =
        dialCode.length + 2 + this.activeCountry.phone_character_limit; */

      const maxLimit = dialCode.length + 2 + 14;
      const minLimit = dialCode.length + 2 + 8;
      //const maxLimit = dialCode == "65" ? 12 : 15;
      //const minLimit = dialCode == "65" ? 12 : 12;

      const rule = [
        this.validateRules.minLength(this.phone, "phone number", minLimit),
        this.validateRules.maxLength(this.phone, "phone number", maxLimit),
      ];
      if (this.required) {
        rule.push(this.validateRules.required(this.phone, "phone number"));
      }
      return rule;
    },
  },
  mounted() {
    this.getActiveCountries();

    this.updateActiveCountry();
    this.setValues();
  },
};
</script>
