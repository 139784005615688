<template>
    <Dialog :dialog.sync="exportDialog">
        <template v-slot:title> Export {{ title }} </template>
        <template v-slot:body>
            <v-form ref="exportForm" v-model.trim="export_valid" lazy-validation v-on:submit.stop.prevent="exportFilter">
                <v-row>
                    <!-- <v-col md="12">
                        <table
                                class="mt-5 font-weight-600 font-size-18 red--text text--lighten-1 w-100"
                            >
                                <tr>
                                <td valign="top" align="right">Note:</td>
                                <td valign="top" align="left">
                                    <em
                                    >The first line in downloaded sample xlsx file should remain
                                    as it is.<br />Please do not change the order of columns.</em
                                    >
                                </td>
                                </tr>
                            </table>
					</v-col>  -->
                    <template>
                        <v-col md="6">
                            <label for="export-from-date" class="required font-weight-700 ml-1">From</label>
                            <DatePicker solo :placeholder="'From'" :default-date="export_from_date
                                " v-model="export_from_date" mandatory: true></DatePicker>
                        </v-col>
                        <v-col md="6">
                            <label for="export-to-date" class="required font-weight-700 ml-1">To</label>
                            <DatePicker solo :placeholder="'To'" :default-date="export_to_date
                                " v-model="export_to_date" mandatory: true></DatePicker>
                        </v-col>
                    </template>
                </v-row>
            </v-form>
        </template>
        <template v-slot:action>
            <v-btn class="mx-2 custom-bold-button"
                      color="cyan white--text"  tile v-on:click="exportFilter">
                Export
            </v-btn>
            <v-btn depressed tile v-on:click="$emit('close', true)"> Close </v-btn>
        </template>
    </Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import DatePicker from "@/view/pages/partials/Datepicker.vue";

//import TextInput from "@/view/components/TextInput.vue";
import Dialog from "@/view/components/Dialog.vue";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import moment from "moment-timezone";
/* import { SET_ERROR } from "@/core/services/store/common.module"; */
import { map } from "lodash";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

export default {
    name: "export-dialog",
    data() {
        return {
            export_as: "xlsx",
            export_type: "all",
            export_password: null,
            export_from_date: moment().format("YYYY-MM-DD"),
            export_to_date: moment().format("YYYY-MM-DD"),
            export_valid: true,
            exportData: [],
        };
    },
    props: {
        exportDialog: {
            type: Boolean,
            default: false,
        },
        current: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        endpoint: {
            type: String,
            default: null,
        },
    },
    methods: {
        exportFilter() {
            const _this = this;
          
            if (_this.export_from_date && _this.export_to_date) {
                if (moment(_this.export_from_date).isAfter(_this.export_to_date)) {
                    ErrorEventBus.$emit(
                    "update:error",
                    InitializeError("End Date must be after Start Date.")
                    );
                    return false;
                }
            }
            if (_this.export_from_date && _this.export_to_date) {
                let startDate = moment(_this.export_from_date);
                let endDate = moment(_this.export_to_date);
                let diffDay = endDate.diff(startDate, "days");
                if (diffDay > 31) {
                    ErrorEventBus.$emit(
                    "update:error",
                    InitializeError("You can not select date more than one month.")
                    );
                    return false;
                }
            }

            let filter = {
                startDate: _this.export_from_date || null,
                endDate: _this.export_to_date || null,
                search: _this.search,
            };

            _this.exportAsset(filter);

            _this.$emit("close", true);
        },
        exportAsset(data) {
            ApiService.setHeader();
            let downloadURL = process.env.VUE_APP_API_URL + this.endpoint;
            downloadURL = new URL(downloadURL);
            const token = JwtService.getToken();
            const { query } = this.$route;

            if (data.startDate != null && data.endDate != null) {
                downloadURL.searchParams.append("start_date", data.startDate);
                downloadURL.searchParams.append("end_date", data.endDate);
            }
            if (query) {
                map(query, (row, index) => {
                    if (row) {
                        downloadURL.searchParams.append(`${index}`, row);
                    }
                });
            }
            downloadURL.searchParams.append("token", token);
            window.open(downloadURL, "_blank");
        },
    },
    components: {
        DatePicker,
        //TextInput,
        Dialog,
    },

    computed: {
        ...mapGetters(["listingSearch", "errors"]),
        /* exportButton() {
            return this.current ? "Export Current View" : "Export";
        }, */
    },
};
</script>
